import React, { useState } from 'react'
import classnames from 'classnames'
import minus from '../../images/svg/minus.svg'
import plus from '../../images/svg/plus.svg'

const Accordion = ({ index, question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <div className="flex">
        <div className="w-10 bg-gray-700 py-6">
          <div className="text-center text-white">{index}</div>
        </div>

        <div className="full px-6 bg-lendis bg-gray-200 tab w-full overflow-hidden border-t">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-black w-full items-center flex justify-between"
          >
            <label
              className="flex justify-between w-full p-5 leading-normal cursor-pointer"
              htmlFor={`tab-multi-one-${index}-${question}`}
            >
              <input type="hidden" id={`tab-multi-one-${index}-${question}`} />
              <p className="text-left pr-1">{question}</p>
              <div>
                {isOpen === true ? (
                  <img className="max-w-xl" src={minus} alt="" />
                ) : (
                  <img className="max-w-xl" src={plus} alt="" />
                )}
              </div>
            </label>
          </button>

          <div
            className={`sm:px-10 pt-4 transition-ease-3 tab-content overflow-hidden leading-loose ${classnames(
              { 'pb-6 tab-content-open': isOpen === true },
            )}`}
          >
            <div dangerouslySetInnerHTML={{ __html: answer }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Accordion
