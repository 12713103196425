import React from 'react'
import Layout from '../components/layout'
import Accordion from '../components/Accordion'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import FaqData from '../config/faq.json'
import Header from '../components/Header'
import BreadCrumb from '../components/Share/BreadCrumb'
const breadcrumb = [
  {
    link: '/',
    title: 'Home',
  },
  {
    title: 'Frequently Asked Questions',
  },
]

const Faq = () => {
  const heroImage =
    process.env.CLOUDINARY_URL +
    '/f_auto/website/39b_1920_darker-annie-spratt-439326-unsplash_uwx8xq.jpg'
  return (
    <Layout>
      <Header page="faq" />
      <Menu />
      <div
        className="big-background-image pb-8  bg-lendis-black bg-no-repeat bg-top lg:pb-40 pt-40 px-3"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="container mx-auto text-white">
          <h1 className="font-bold lg:text-6xl px-8 text-3xl mb-5">Frequently Asked Questions</h1>
        </div>
        <BreadCrumb breadCrumbItems={breadcrumb} textPage />
        <div className="bg-white mx-auto container">
          <div className="py-12">
            <div className="ml-8 mt-8 lg:ml-16 lg:mr-16">
              {FaqData.map((item, idy) => {
                return (
                  <div key={idy}>
                    <h2 className="text-2xl uppercase mt-10">{item['title']}</h2>
                    <div className="-ml-8 mt-8 lg:ml-0">
                      {item['data'].map((faq, idx) => (
                        <div key={idx} className="mt-2">
                          <Accordion index={idx + 1} question={faq.question} answer={faq.answer} />
                        </div>
                      ))}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Faq
